import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"
import { graphql } from "gatsby"

export const servicesQuery = graphql`
  query servicesQuery {
    wpPage(databaseId: { eq: 66 }) {
      title
      content
      whatWeDo {
        section1Subtitle
        section1Title
        section2Subtitle
        section2Title
        section1Services {
          section1ServiceTitle
          section1ServiceIcon {
            altText
            localFile {
              publicURL
            }
          }
        }
        section2Services {
          section2ServiceTitle
          section2ServiceIcon {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default function Services({ data }) {
  return (
    <Layout
      bodyClass="services"
      heroTitle={data.wpPage.title}
      heroText={data.wpPage.content}
      bcText="SERVICES"
    >
      <section className="services-consultancies">
        <Container>
          <div className="row services-box-title">
            <Col sm={12}>
              <h2>{data.wpPage.whatWeDo.section1Title}</h2>
              <span className="red-subtitle">
                {data.wpPage.whatWeDo.section1Subtitle}
              </span>
            </Col>
          </div>
          <Row className="services-icons">
            {data.wpPage.whatWeDo.section1Services.map(service => (
              <Col sm={6} md={6} lg={3} xl={3}>
                <div className="icon-box">
                  <img
                    src={service.section1ServiceIcon.localFile.publicURL}
                    alt={service.section1ServiceIcon.altText}
                  />
                  <p>{service.section1ServiceTitle}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="services-development">
        <Container>
          <Row className="services-box-title">
            <Col sm={12}>
              <h2>{data.wpPage.whatWeDo.section2Title}</h2>
              <span className="red-subtitle">
                {data.wpPage.whatWeDo.section2Subtitle}
              </span>
            </Col>
          </Row>
          <Row className="services-dev">
            <Col sm={12} md={12} lg={6}>
              <img src="/images/software-dev.jpg" alt="" />
            </Col>
            <Col sm={12} md={12} lg={6}>
              {data.wpPage.whatWeDo.section2Services.map(service => (
                <div className="icon-box">
                  <img
                    src={service.section2ServiceIcon.localFile.publicURL}
                    alt={service.section2ServiceIcon.altText}
                  />
                  <p>{service.section2ServiceTitle}</p>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
